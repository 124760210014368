


























































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import Papa from "papaparse";
import { namespace } from "vuex-class";
import Vuelidate from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { isEmpty } from "lodash";

import {
  TransactionType,
  PaymentMode,
  Batch,
  OrgAccount,
  UserInfo,
  Txn,
  Currencies,
} from "@/types";
import {
  Dropdown,
  CustomInput,
  SvgIcon,
  AccountsDropdown,
  Loader,
} from "@/components";
import { formatAmount, formatCurrency } from "@/utils/common";
import { Bank } from "@/proto/banks/all_pb";
import SuccessUploadModal from "./components/SuccessUploadModal.vue";

const transactionsModule = namespace("transactions");
const userModule = namespace("user");
const configModule = namespace("config");

Vue.use(Vuelidate);

@Component({
  components: {
    SuccessUploadModal,
    Dropdown,
    CustomInput,
    SvgIcon,
    AccountsDropdown,
    Loader,
  },
  validations: {
    sourceAccount: {
      hasChosen: (value) => !isEmpty(value),
    },
    uploadedFile: {
      required,
    },
  },
})
export default class UploadFileForm extends Vue {
  @userModule.Getter("currentUserInfo") userInfo!: UserInfo;
  @configModule.Getter("pesonetBanks") pesonetBanks!: Array<Bank.AsObject>;
  @transactionsModule.Action("createBatch") createBatch!: (
    payload: Batch
  ) => void;

  error = "";
  fileError = "";
  fileName = "Upload File";
  totalAmount = 0;
  loading = false;
  sourceAccount: OrgAccount | Record<string, any> = {};
  reference = "";
  uploadedFile: File | null = null;
  transactionList: Array<Txn> = [];
  transactionTypeList: Array<any> = [];
  transactionType = TransactionType.PayrollExecutive;
  settlementRail = PaymentMode.EFT;

  // Make this an object, so watch will always run
  downloadTemplate: Record<string, any> = {};

  created() {
    this.transactionTypeList = [TransactionType.PayrollExecutive];
  }

  onSelectFile(evt: any) {
    const uploadedFile = evt.target.files[0] as File;

    this.fileError = "";
    this.uploadedFile = uploadedFile;
    this.fileName = uploadedFile.name;

    const reader = new FileReader();
    reader.readAsText(this.uploadedFile as any);
    reader.onload = this._processCSV;
    reader.onerror = () => {
      this.fileError = "Error reading file";
    };
  }

  updateDownloadTemplate(chosenTemplate: PaymentMode) {
    this.downloadTemplate = { value: chosenTemplate };
  }

  get DOWNLOAD_TEMPLATE_LIST() {
    return [
      {
        label: "Cebuana Bank Template",
        value: PaymentMode.EFT,
      },
      {
        label: "Pesonet Template",
        value: PaymentMode.Pesonet,
      },
    ];
  }

  get EFT_REQUIRED_COLUMNS() {
    return ["CreditAccount", "CreditAccountName", "CreditAmount"];
  }

  get PESONET_REQUIRED_COLUMNS() {
    return [
      "Amount",
      "Receiver Bank Name",
      "Receiver A/C Number",
      "Receiver Name",
      "Receiver Address",
    ];
  }

  get SETTLEMENT_RAILS() {
    return [
      {
        label: "Transfer to Cebuana Bank Accounts",
        value: PaymentMode.EFT,
      },
      {
        label: "Transfer to Other Bank via PesoNet",
        value: PaymentMode.Pesonet,
      },
    ];
  }

  get availableBalance() {
    if (this.sourceAccount.availableBalance) {
      return formatCurrency(this.sourceAccount.availableBalance);
    }

    return false;
  }

  get formattedTotalAmount() {
    return formatCurrency(this.totalAmount);
  }

  _processCSV(evt: ProgressEvent<FileReader>) {
    try {
      const result = this._handleCSV(evt.target?.result as string);
      this.transactionList = result.list;
      this.totalAmount = result.totalAmount;
    } catch (error) {
      const err = error as Error;
      this.fileError = err.message;
    }
  }

  _validateColumns(headers: Array<any>) {
    let baseColumns;

    if (this.settlementRail === PaymentMode.EFT) {
      baseColumns = this.EFT_REQUIRED_COLUMNS;
    } else if (this.settlementRail === PaymentMode.Pesonet) {
      baseColumns = this.PESONET_REQUIRED_COLUMNS;
    } else {
      throw new Error(`Invalid settlement rail`);
    }
    baseColumns.forEach((column: string) => {
      const columnExist = headers.find((header: string) => header === column);
      if (!columnExist) {
        throw new Error(`Missing required column - ${column}`);
      }
    });
  }

  _parseCSV(csv: string) {
    const parsed = Papa.parse(csv, {
      header: true,
    });

    if (parsed.errors.length) {
      const rowErrors: Array<any> = [];

      parsed.errors.forEach((error: any) => {
        if (error.code === "TooFewFields") {
          rowErrors.push(error.row);
        }
      });

      parsed.data = parsed.data.filter((row: any, i: number) => {
        if (rowErrors.find((err) => err === i)) {
          return false;
        }

        return true;
      });
    }

    parsed.data = parsed.data.filter((row: any) => {
      for (const field in row) {
        if (row[field] !== "") {
          return true;
        }
      }

      return false;
    });

    return parsed;
  }

  _getBankData(bankName: string) {
    const bank = this.pesonetBanks.find((bank) => {
      return bank.name === bankName;
    });
    return bank;
  }

  _processEFT(parsedData: Array<any>) {
    let totalAmount = 0;

    const list = parsedData.map((row: any) => {
      // const name = row.CreditAccountName.split(",");
      totalAmount += parseInt(row.CreditAmount);

      return {
        amount: formatAmount(row.CreditAmount),
        destination: {
          accountNumber: row.CreditAccount,
          firstName: row.CreditAccountName,
          lastName: "",
          accountName: row.CreditAccountName,
          bankCode: "CLRB",
        },
        currency: Currencies.PHP,
      };
    });

    return {
      totalAmount,
      list,
    };
  }

  _processPESONET(parsedData: Array<any>) {
    let totalAmount = 0;
    const list = parsedData.map((row: any, i) => {
      totalAmount += parseInt(row.Amount);
      const bankData = this._getBankData(row["Receiver Bank Name"]);

      if (!bankData?.rails?.pesonet) {
        throw new Error(
          `Bank not available via Pesonet, or Bank name is wrong. Row ${i + 1}`
        );
      }

      return {
        amount: formatAmount(row.Amount),
        destination: {
          accountNumber: row["Receiver A/C Number"],
          firstName: row["Receiver Name"],
          lastName: row["Receiver Name"],
          accountName: row["Receiver Name"],
          bankCode: bankData?.rails?.pesonet,
        },
        currency: Currencies.PHP,
      };
    });

    return {
      totalAmount,
      list,
    };
  }

  _handleCSV(csv: string) {
    const parsed = this._parseCSV(csv);

    this._validateColumns(parsed.meta.fields);

    if (parsed.data.length === 0) {
      throw new Error("No rows processed");
    }

    if (this.settlementRail === PaymentMode.EFT) {
      return this._processEFT(parsed.data);
    } else if (this.settlementRail === PaymentMode.Pesonet) {
      return this._processPESONET(parsed.data);
    } else {
      throw new Error(`Invalid settlement rail. Can't process rows`);
    }
  }

  async _onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) return;

    if (this.fileError) return;

    this.loading = true;

    try {
      const batch: Batch = {
        source: {
          accountNumber: this.sourceAccount.accountNumber,
          firstName: this.userInfo.firstName as string,
          lastName: this.userInfo.lastName as string,
        },
        settlementRail: this.settlementRail,
        remarks: this.reference,
        transactions: this.transactionList,
        txnSource: "batch",
      };

      await this.createBatch(batch);
      this.$modal.show("success-upload-modal");
    } catch (error) {
      const err = error as Error;
      this.fileError = err.message;
    } finally {
      this.loading = false;
    }
  }

  _setSourceAccount(payload: OrgAccount) {
    this.sourceAccount = payload;
  }

  closeModal() {
    // Set defaults when closing modal
    this.sourceAccount = {};
    this.transactionType = TransactionType.PayrollExecutive;
    this.settlementRail = PaymentMode.EFT;
    this.reference = "";
    this.uploadedFile = null;
    this.totalAmount = 0;
    this.transactionList = [];

    this.$modal.hide("success-upload-modal");
  }

  @Watch("downloadTemplate", { immediate: true })
  watchDownloadTemplate(chosenTemplate: Record<string, any>) {
    if (chosenTemplate.value) {
      const template = this.$refs[
        `${chosenTemplate.value}_TEMPLATE`
      ] as HTMLElement;
      template.click();
    }
  }
}
